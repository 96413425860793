import {Button, Col, Divider, Row, Typography} from "@douyinfe/semi-ui";
import React, { Fragment } from "react";

export default function HomeContent() {

    const { Title } = Typography;

    const width = window.innerWidth - 17

    const titleStyle = {
        /*color: 'white',*/
        margin: width > 820? '128px 0 40px 0' : '40px 0 40px 0',
        fontSize: width > 820 ? '350%' : '240%',
        lineHeight: '1.2'
    }

    React.useEffect (() => {

    },[width])

    return (
        <Fragment>
            <div className={'grid'}>
                <Row type={'flex'} justify="center">
                    <Col xs={22} xl={14}>
                        <Row type={'flex'} justify="center">
                            <Col xs={24} xl={12}>
                                <Title style={ titleStyle }>Data transmission at any scale to anywhere</Title>
                                <Title heading={3} style={{ margin: '25px 0 50px 0'}}>Access our networks to content providers, to your company networks and to public clouds.</Title>
                                <Row type={'flex'} justify="center" gutter={16}>
                                    <Col xs={24} lg={12}>
                                        <Button size={'large'} theme={'light'} block style={{ margin: '0 0 16px 0'}}><font style={{ lineHeight: '1.2' }}>Pricing</font></Button>
                                    </Col>
                                    <Col xs={24} lg={12}>
                                        <Button size={'large'} theme={'solid'} block style={{ margin: '0 0 16px 0'}}><font style={{ lineHeight: '1.2' }}>Contact Us</font></Button>
                                    </Col>
                                </Row>
                                <Title heading={6} style={{ margin: '25px 0 50px 0'}}>High quality network across continents.</Title>
                            </Col>
                            <Col xs={24} xl={12}>
                            </Col>
                        </Row>
                        <Divider margin='16px'/>
                        <Row type={'flex'} justify="center">
                            <Col xxs={24} sm={20} xxl={14}>
                                <Title style={ titleStyle }>Our infrastructure-based services are with <font style={{ color: '#FF73B3' }}>features</font> you love</Title>
                            </Col>
                        </Row>
                        <Row type={'flex'} justify="center" gutter={16}>
                            <Col xs={24} lg={7}>
                                <Title heading={3} style={{ margin: '25px 0 50px 0'}}>High Speed</Title>
                                <Title heading={6} style={{ margin: '0 0 50px 0'}}>We provide direct fiber networks to your workplace, up to 100 Gbps.</Title>
                            </Col>
                            <Col xs={24} lg={7}>
                                <Title heading={3} style={{ margin: '25px 0 50px 0'}}>Reliable</Title>
                                <Title heading={6} style={{ margin: '0 0 50px 0'}}>Keep your services online all the time at every day.</Title>
                            </Col>
                            <Col xs={24} lg={7}>
                                <Title heading={3} style={{ margin: '25px 0 50px 0'}}>Low Cost</Title>
                                <Title heading={6} style={{ margin: '0 0 50px 0'}}>Our service has never been so cost effective. Save money.</Title>
                            </Col>
                        </Row>
                        <Row type={'flex'} justify="center">
                            <Col span={24}>
                                <Divider margin='16px'/>
                            </Col>
                        </Row>
                        <Row type={'flex'} justify="center">
                            <Col span={24}>
                                <Title heading={1} style={{ margin: '50px 0 25px 0', fontSize: '350%', lineHeight: '1.2'}}>Network</Title>
                                <Title heading={4}>24/7 monitoring and daily routing optimization through multiple transit providers worldwide.</Title>
                            </Col>
                        </Row>
                        <Row type={'flex'} justify="center" gutter={16}>
                            <Col xs={24} lg={12}>
                                <Title heading={1} style={{ margin: '50px 0 16px 0',}}>Immense bandwidth capacity</Title>
                                <Title heading={6}>Our network architecture is designed to take on a massive volume of traffic. We use multiple transit providers in each of our locations to ensure top quality and sufficient capacity.</Title>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Title heading={1} style={{ margin: '50px 0 16px 0',}}>Global coverage with local routing</Title>
                                <Title heading={6} >Strategically distributed data centers across Europe, the United States, and Asia enable direct connection to most of the local internet service providers.</Title>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Title heading={1} style={{ margin: '50px 0 16px 0',}}>Zero packet loss and no network saturation</Title>
                                <Title heading={6} >The exceptional quality of data delivery from our dedicated servers to the end-users is the core focus of our company. We regularly monitor our network to avoid packet loss and saturation.</Title>
                            </Col>
                            <Col xs={24} lg={12}>
                                <Title heading={1} style={{  margin: '50px 0 16px 0',}}>Direct access to hard‑to‑reach networks</Title>
                                <Title heading={6} >To ensure the best possible network quality even in peak times, we partner up with premium ISPs such as Comcast, Telefonica, UPC, Swisscom or Deutsche Telekom.</Title>
                            </Col>
                            <Col span={24}>
                                <div style={{ margin: '50px 0' }}></div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )

}