import {Button, Col, Divider, Row, Typography} from "@douyinfe/semi-ui";
import React, { Fragment } from "react";

export default function IPTContent() {

    const { Title } = Typography;

    const width = window.innerWidth - 17

    const titleStyle = {
        /*color: 'white',*/
        margin: '40px 0 40px 0',
        fontSize: width > 820 ? '350%' : '240%',
        lineHeight: '1.2'
    }

    React.useEffect (() => {

    },[width])

    return (
        <Fragment>
            <div className={'grid'}>
                <Row type={'flex'} justify="center">
                    <Col xs={22} xl={14}>
                        <Row type={'flex'} justify="center">
                            <Col span={24}>
                                <Title style={ titleStyle }>IP Transit</Title>
                                <Title heading={3} style={{ margin: '25px 0 50px 0'}}>Get access to more networks directly with lower latency and fewer hops by getting a High Speed IP Transit Connection from TGs4 Networks.</Title>
                                <Title heading={6} style={{ margin: '25px 0'}}>Our network supports IPv6 and IPv4 over the same connection at no additional charge. Once you get an IP Transit connection from TGs4 Networks you will be already set to add IPv6 support too for your customers.</Title>
                                <Title heading={6} style={{ margin: '0 0 50px 0'}}>All locations have 200/100/40/25/10 Gbps connections - Each of our core routers and switchs are support up to 200Gbps for single port connections. Multiple port with LACP is also supported.</Title>
                            </Col>
                        </Row>
                        <Divider margin='16px'/>
                        <Row type={'flex'} justify="center">
                            <Col span={24}>
                                <Title style={ titleStyle }>Jumbo Frames</Title>
                                <Title heading={5} style={{ margin: '25px 0 50px 0'}}>TGs4's entire network is Jumbo Frame (9000 byte MTU) capable to allow higher data throughput. Jumbo frames allow you to more easily attain higher data speeds for large data transfers between geographically separated locations when both locations have jumbo frame capability. Fiber Internet Connections - Get IP Transit via fiber at 1310nm or 1550nm (singlemode) using your SFP, SFP+, QSFP, QSFP28 or QSFP56.</Title>
                            </Col>
                        </Row>
                        <Row type={'flex'} justify="center">
                            <Col span={24}>
                                <Divider margin='16px'/>
                            </Col>
                        </Row>
                        <Row type={'flex'} justify="center" gutter={16}>
                            <Col span={24}>
                                <Title style={ titleStyle }><font style={{ color: '#FF73B3' }}>Mainland China</font> Optimized</Title>
                                <Title heading={6} style={{ margin: '0 0 50px 0'}}>With our partner China Telecom, China Union and China Mobile, our network provides options for China mainland optimized connectivity. This can help your applications have the better loading speed when your users are accessing from Mainland China.</Title>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )

}