import React, {Fragment} from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import "./App.css";

import AppLayout from './pages/layout'
import HomeContent from './pages/home'
import HPCContent from './pages/hpc'
import IPTContent from './pages/iptransit'

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path={'/'} element={<AppLayout />}>
                  <Route path={''} element={<HomeContent />} />
                  <Route path={'hpcsolution'} element={<HPCContent />} />
                  <Route path={'iptransit'} element={<IPTContent />} />
              </Route>
          </Routes>
      </BrowserRouter>
  )
}

export default App