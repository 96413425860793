import {Layout, Nav, Dropdown, Row, Col, Button, Divider, Typography} from "@douyinfe/semi-ui";
import {IconHome, IconLive, IconSemiLogo, IconSetting} from "@douyinfe/semi-icons";
import { React, Fragment } from "react";

export default function AppFooter() {

    const { Header, Footer, Content } = Layout;
    const { Title, Text } = Typography;

    return (
        <Footer style={{ backgroundColor: 'var(--semi-color-bg-1)' }}>
            <div className={'grid'}>
                <Row type={'flex'} justify="center">
                    <Col xs={22} xl={14}>
                        <Row type={'flex'} justify="center">
                            <Col span={24}>
                                <Divider margin='36px'/>
                            </Col>
                        </Row>
                        <Row type={'flex'} justify={"center"}>
                            <Col xs={12} xl={6} style={{ margin: '16px 0' }}>
                                <Title heading={4}>Service</Title><br/>
                                <Title heading={6}>Cloud Computing</Title><br/>
                                <Title heading={6}>GPU Cloud</Title><br/>
                                <Title heading={6}>Cloud Storage</Title><br/>
                            </Col>
                            <Col xs={12} xl={6} style={{ margin: '16px 0' }}>
                                <Title heading={4}>Infrastructure</Title><br/>
                                <Title heading={6}>Dedicated Servers</Title><br/>
                                <Title heading={6}>Dedicated GPU Servers</Title><br/>
                                <Title heading={6}>Dedicated Storage</Title><br/>
                            </Col>
                            <Col xs={12} xl={6} style={{ margin: '16px 0' }}>
                                <Title heading={4}>Solutions</Title><br/>
                                <Title heading={6}>Custom Build Servers</Title><br/>
                                <Title heading={6}>Custom Build GPU Servers</Title><br/>
                                <Title heading={6}>Custom Build Storage</Title><br/>
                            </Col>
                            <Col xs={12} xl={6} style={{ margin: '16px 0' }}>
                                <Title heading={4}>Support</Title><br/>
                                <Title heading={6}>Support</Title><br/>
                                <Title heading={6}>Contact</Title><br/>
                                <Title heading={6}>FAQ</Title><br/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className={'grid'}>
                <Row type={'flex'} justify="center">
                    <Col xs={22} xl={14}>
                        <Row type={'flex'} justify="center">
                            <Col span={24}>
                                <Title heading={1} style={{ margin: '25px 0 0 0', fontSize: '150%' }}>TGs4 Networks</Title>
                            </Col>
                            <Col span={24}>
                            </Col>
                        </Row>
                        <Row type={'flex'} justify="center">
                            <Col span={24}>
                                <Divider margin='16px'/>
                            </Col>
                        </Row>
                        <Row type={'flex'} justify="center" style={{ margin: '0 0 25px 0' }}>
                            <Col span={24}>
                                © 2023 TGs4 Networks Inc. All rights reserved.
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Footer>
    )

}