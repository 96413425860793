import {Button, Col, Divider, Row, Typography} from "@douyinfe/semi-ui";
import React, { Fragment } from "react";

export default function HPCContent() {

    const { Title } = Typography;

    const width = window.innerWidth - 17

    const titleStyle = {
        /*color: 'white',*/
        margin: width > 820? '128px 0 40px 0' : '40px 0 40px 0',
        fontSize: width > 820 ? '350%' : '240%',
        lineHeight: '1.2'
    }

    React.useEffect (() => {

    },[width])

    return (
        <Fragment>
            <div className={'grid'}>
                <Row type={'flex'} justify="center">
                    <Col xs={22} xl={14}>
                        <Row type={'flex'} justify="center">
                            <Col span={24}>
                                <Title style={ titleStyle }>HPC Solution</Title>
                                <Title heading={3} style={{ margin: '25px 0 50px 0'}}>Powering the future with High Performance Computing</Title>
                                <Title heading={6} style={{ margin: '25px 0 50px 0'}}>Cutting-edge High Performance Computing infrastructure that powers everything from gaming platforms, VFX rendering, Blockchain, Machine learning, Advanced Simulation, Video transcoding, Video Analytics, financial services, <font style={{ color: '#FF73B3' }}>plus</font> everything in between.</Title>
                            </Col>
                        </Row>
                        <Divider margin='16px'/>
                        <Row type={'flex'} justify="center">
                            <Col xxs={24} sm={20} xxl={14}>
                                <Title style={ titleStyle }>State-Of-Art <font style={{ color: '#FF73B3' }}>network</font> infrastructure you love</Title>
                                <Title heading={5} style={{ margin: '25px 0 50px 0'}}>In the current landscape of advanced technology, many applications not only need high performance within each compute nodes in the cluster, but also it also require transfer massive amount of data between compute nodes in the cluster. Much more, they all require scalable and best in class CPU, GPU, I/O and networking capabilities. With our network infrastructure supporting up to 400Gbps in a single port, we can create a worry-free experience for you: This means that when you need to run a large task on a cluster with multiple computation nodes, you won't have to worry about communication efficiency between the nodes.</Title>
                            </Col>
                        </Row>
                        <Row type={'flex'} justify="center">
                            <Col xs={24} xxl={14}>
                                <Divider margin='16px'/>
                            </Col>
                        </Row>
                        <Row type={'flex'} justify="center">
                            <Col xs={24} xxl={14}>
                                <Title heading={1} style={{ margin: '50px 0 25px 0', fontSize: '350%', lineHeight: '1.2'}}>Custom Build Cluster</Title>
                                <Title heading={4}>Hope to build a HPC cluster in your own datacenter?</Title>
                                <Title heading={6} style={{ margin: '25px 0 50px 0'}}><font style={{ color: '#FF73B3' }}>No problem.</font> We also provide custom cluster building service. We can offer complete solution<font style={{ color: '#FF73B3' }}>s</font> according to the requirement<font style={{ color: '#FF73B3' }}>s</font> of your applications and business. Further more, with our partner<font style={{ color: '#FF73B3' }}>s</font> we also can provide the hardware needed for the solution in an economical price.</Title>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Fragment>
    )

}