import React from 'react';
import {Layout, Nav, SideSheet} from '@douyinfe/semi-ui';
import {
    IconHome,
    IconSetting,
    IconMenu
} from '@douyinfe/semi-icons';
import {Outlet} from "react-router-dom";
import AppFooter from "../components/footer";

export default function AppLayout() {

    const { Header, Content } = Layout;

    const width = window.innerWidth - 17

    const [ visible, setVisible ] = React.useState(false)

    React.useEffect(() => {
        const body = document.body;
        if (body.hasAttribute('theme-mode')) {
            body.removeAttribute('theme-mode');
        } else {
            body.setAttribute('theme-mode', 'dark');
        }
    }, [])

    return (
        <Layout style={{ height: '100vh' }} >
            <Header style={{ backgroundColor: 'var(--semi-color-bg-1)' }}>
                {
                    width < 820 ?
                        <Nav mode="horizontal" defaultSelectedKeys={['Home']}>
                            <Nav.Header>
                                TGs4 Networks
                            </Nav.Header>
                            <Nav.Footer>
                                <div onClick={() => setVisible(!visible)}><IconMenu /></div>
                            </Nav.Footer>
                        </Nav> :
                        <Nav mode="horizontal" defaultSelectedKeys={['home']}>
                            <Nav.Header>
                                TGs4 Networks
                            </Nav.Header>
                            <Nav.Item link={"/"} itemKey="home" text="Home" icon={<IconHome size="large" />} />
                            <Nav.Item link={"/iptransit"} text="IP Transit" icon={<IconSetting size="large" />} />
                            <Nav.Item link={"/hpcsolution"} text="HPC Solution" icon={<IconSetting size="large" />} />
                        </Nav>
                }
            </Header>
            <Layout>
                {
                    width < 820 ?
                        <SideSheet title="TGs4 Networks"
                                   visible={visible}
                                   onCancel={() => setVisible(!visible)}
                                   placement="top"
                                   height='30vh'
                        >
                            <Nav mode="vertical" defaultSelectedKeys={['home']} style={{ width: '100%' }}>
                                <Nav.Item link={"/"} itemKey="home" text="Home" icon={<IconHome size="large" />} />
                                <Nav.Item link={"/iptransit"} text="IP Transit" icon={<IconSetting size="large" />} />
                                <Nav.Item link={"/hpcsolution"} text="HPC Solution" icon={<IconSetting size="large" />} />
                            </Nav>
                        </SideSheet> : null

                }
                <Content style={{ height: '100%' }}>
                    <Outlet />
                </Content>
            </Layout>
            <AppFooter />
        </Layout>
    );
}